import {useRouteError} from "react-router-dom";


export default function ErrorPage() {
    const error: any = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <h1>Connies Don't Look Great</h1>
            <p>Maybe stick to the cafe today instead.</p>
            <p>
                Error: <i>{ error.statusText || error.message }</i>
            </p>
        </div>
    )
}
import {Autocomplete, Box, Grid, TextField, Typography} from "@mui/material";
import LandscapeRoundedIcon from '@mui/icons-material/LandscapeRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from "react";

interface SearchResult {
    label: string;
    description: string;
    link: string;
    icon: string;
    path: { label: string, link: string }[]
}

export default function Search() {
    const [options, setOptions] = useState<SearchResult[]>([]);
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Simulate fetch
        setTimeout(() => {
            setOptions([
                {
                    label: "Coolum",
                    description: "South East Qld > Coolum",
                    link: "coolum",
                    icon: "crag",
                    path: [
                        {label: "Australia", link: "12-australia"},
                        {label: "South East Queensland", link: "13-seq"},
                    ]
                },
                {
                    label: "Lower Slider",
                    description: "South East Qld > Slider",
                    link: "slider",
                    icon: "wall",
                    path: [
                        {label: "Australia", link: "12-australia"},
                        {label: "South East Queensland", link: "13-seq"},
                    ]
                }
            ]);
        }, 2000);
    }, [inputValue]);

    return (
        <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Autocomplete
                renderInput={(params) =>
                    <TextField
                        {...params}
                        sx={{width: 400}}
                        placeholder="Search Climb Clime"
                        inputProps={{...params.inputProps, 'aria-label': 'search climb clime'}}
                    />
                }
                autoComplete
                filterSelectedOptions
                getOptionLabel={(o) => o.label}
                options={options}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                    if (newValue === null) {
                        return;
                    }
                    navigate(`wall/${newValue.link}`, {state: {...newValue}})
                }}
                renderOption={(props, option) =>
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{display: 'flex', width: 44}}>
                                {option.icon === "crag"
                                    ? < LandscapeRoundedIcon sx={{color: 'text.secondary'}}/>
                                    : < LocationOnRoundedIcon sx={{color: 'text.secondary'}}/>
                                }
                            </Grid>
                            <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                                <Box
                                    component="span"
                                    sx={{fontWeight: 'bold'}}
                                >
                                    {option.label}
                                </Box>
                                <Typography variant="body2" color="text.secondary">
                                    {option.description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                }
            />
        </div>
    )
}
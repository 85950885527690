import ClimbingMap from "../map/ClimbingMap";
import SunChart from "../SunChart";
import {useLocation} from "react-router-dom";
import {Box, Breadcrumbs, Link, Tab, Tabs, Typography} from "@mui/material";
import {ReactNode, SyntheticEvent, useEffect, useState} from "react";
import config from "../config.ts";


interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role={"tabpanel"}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Wall() {
    const {state} = useLocation();
    const {label, path} = state;

    const [selectedTab, setSelectedTab] = useState(0);
    const [wallData, setWallData] = useState<any>(null);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        async function fetchData() {
            const sep = "%23"
            fetch(
                `${config.BACKEND_URL}/wall/` +
                `AREA${sep}${path.map((p: any) => p.label).join(sep)}${sep}WALL${sep}${label}`
            )
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    console.log("Error fetching wall data: ", response);
                    throw response;
                })
                .then(json => {
                    setWallData(json.wallData)
                })
                .catch(err => {console.log("err parsing wall data: ", err); setWallData({error: err});})
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchData()
    }, [label, path])

    return (
        <div style={{
            height: '100%', display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: "space-around",
            width: '100%'
        }}>
            <div style={{width: '80%', height: '50vh'}}>
                <h1>{label}</h1>
                <Breadcrumbs maxItems={4} aria-label="breadcrumb">
                    {path.map((p: any) => (
                        <Link underline="hover" color="inherit" href={p.link} key={p.link}>
                            {p.label}
                        </Link>
                    ))}
                    <Typography color="text.primary">{label}</Typography>
                </Breadcrumbs>
                {wallData && (
                 wallData?.error
                     ? <div style={{width: "100%", height: '100%', display: 'flex', alignItems: "center", justifyContent: "center"}}>
                         <div>Could not load wall</div>
                       </div>
                     : <ClimbingMap wall={wallData} windDirection={30}/>
                )}
            </div>
            <div>
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Today" {...a11yProps(0)} />
                            <Tab label="Forecast" {...a11yProps(1)} />
                            <Tab label="Yearly" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedTab} index={0}>
                        <SunChart dataFile="/outputs/19-coolum-cave_test.csv" drawXAxis={true}/>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <SunChart dataFile="/outputs/19-coolum-cave_test.csv" drawXAxis={true}/>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <SunChart dataFile="/outputs/19-coolum-cave_test.csv" drawXAxis={true}/>
                    </TabPanel>
                </Box>
            </div>
        </div>
    )
}
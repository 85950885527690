/**
 * Created by Matt on 16/01/2016.
 */
import * as d3 from "d3";
import {useEffect, useRef} from "react";

interface Props {
    // Input Data csv url.
    dataFile: string,
    // If it's the last, draw the x-axis labels.
    drawXAxis: boolean
}

interface SunChartPoint {
    date: Date,
    time: Date,
    shade: number
    intensity: number,
}

export default function SunChart({dataFile, drawXAxis}: Props) {
    const d3Element = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (d3Element.current == null) return;
        const chartID = (Math.random() + 1).toString(36).substring(7);

        const margin = {top: 0, right: 40, bottom: (drawXAxis ? 40 : 0), left: 40}
        const width = 700 - margin.left - margin.right
        const height = (drawXAxis ? 80 : 40) - margin.top - margin.bottom;

        const parseTime = d3.timeParse("%H:%M:%S");
        const formatTime = d3.timeFormat("%I:%M %p");
        const parseDate = d3.timeParse("%d/%m/%Y");
        const x = d3.scaleTime()
            .range([0, width]);

        const y = d3.scaleLinear()
            .range([height, 0]);

        // const graph_type = "combo2"; //"shade" "intensity" "combination"
        // const getY = function(d) {return y(d.shade);};
        // const getYN = function(d) {return d.shade;};
        // const getYRange = function(d) { return d3.extent(d, getYN); };
        //     if (graph_type == "intensity") {
        //         getY = function(d) { return y(d.intensity);};
        //         getYN = function(d) { return d.intensity;};
        //         getYRange = function(d) { return [0, 100];};
        // //                getYRange = function(d) { return d3.extent(d, getYN); };
        //     } else if (graph_type == "combination") {
        //         getY = function(d) { return y(d.est); };
        //         getYN = function(d) { return d.est; };
        // //                getYRange = function(d) { return [0, 1]; };
        //         getYRange = function(d) { return d3.extent(d, getYN); };
        //     } else if (graph_type == "combo2") {
        const getY = (d: SunChartPoint) => y(d.shade)
        const getYN = (d: SunChartPoint) => d.intensity
        const getYNShade = (d: SunChartPoint) => d.shade
        const getYRange = (d: SunChartPoint[]) => d3.extent(d, getYNShade)
        // }

        const xAxis = d3.axisBottom(x)
            .scale(x)
            .tickSize(-height);

        const area = d3.area<SunChartPoint>()
            .curve(d3.curveBasis)
            .x((d) => x(d.time))
            .y0(height)
            .y1(getY);

        const line = d3.line<SunChartPoint>()
            .curve(d3.curveBasis)
            .x((d) => x(d.time))
            .y(getY);

        // Create the svg drawing canvas...
        const chartEl = document.createElement('div');
        d3Element.current.appendChild(chartEl);
        const svg = d3.select(chartEl).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .attr("style", "display: block;")
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        d3.csv(dataFile).then((csvData) => {
            const sunrise = parseTime(csvData[0].time as string);
            const sunset = parseTime(csvData[1].time as string);
            csvData.splice(0, 2); // Remove first 2 elements from array

            const data: SunChartPoint[] = csvData.map(row => ({
                // Throw an exception
                date: parseDate(row.date as string)!,
                time: parseTime(row.time as string)!,
                shade: +(row?.shade || 0),
                intensity: +(row?.intensity || 0),
            }));


            const xExtent = d3.extent(data, d => d.time);
            const yAxisDomain = getYRange(data);
            if (yAxisDomain[0] === undefined && yAxisDomain[1] === undefined) {
                throw new Error("y axis domain could not be determined from data.")
            }
            if (xExtent[0] === undefined && xExtent[1] === undefined) {
                throw new Error("x axis domain could not be determined from data.")
            }
            x.domain(xExtent);
            y.domain(yAxisDomain);

            const day_gradient = svg.append("defs")
                .append("linearGradient")
                .attr("id", "day_gradient")
                .attr("x1", "0%")
                .attr("y1", "0%")
                .attr("x2", "0%")
                .attr("y2", "100%")
                .attr("spreadMethod", "pad");
            day_gradient.append("stop")
                .attr("offset", "0%")
                .attr("stop-color", "#4BF3FF")
                .attr("stop-opacity", 1);
            day_gradient.append("stop")
                .attr("offset", "100%")
                .attr("stop-color", "#FFF")
                .attr("stop-opacity", 1);

            const night_gradient = svg.append("defs")
                .append("linearGradient")
                .attr("id", "night_gradient")
                .attr("x1", "0%")
                .attr("y1", "0%")
                .attr("x2", "0%")
                .attr("y2", "100%")
                .attr("spreadMethod", "pad");
            night_gradient.append("stop")
                .attr("offset", "0%")
                .attr("stop-color", "#000")
                .attr("stop-opacity", 1);
            night_gradient.append("stop")
                .attr("offset", "100%")
                .attr("stop-color", "#444")
                .attr("stop-opacity", 1);

            if (sunrise && sunset) {
                // Day rectangle
                svg.append("rect")
                    .attr("x", x(sunrise))
                    .attr("y", 0)
                    .attr("width", x(sunset) - x(sunrise))
                    .attr("height", height)
                    .style("fill", "url(#day_gradient)");
                // Night rectangle 1
                svg.append("rect")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("width", x(sunrise))
                    .attr("height", height)
                    .style("fill", "url(#night_gradient)");
                // Night rectangle 2
                svg.append("rect")
                    .attr("x", x(sunset))
                    .attr("y", 0)
                    .attr("width", width - x(sunset))
                    .attr("height", height)
                    .style("fill", "url(#night_gradient)");
            }

            const yColMax = d3.max(data, getYN) || 1;
            const stopData = [];
            for (let i = 0; i < data.length - 1; i++) {
                const b = (getYN(data[i]) + getYN(data[i + 1])) / 2;
                const val = Math.round((b / yColMax) * 255.0);
                const col = "rgb(" + val + "," + val + ", 0)";
                const offset = x(data[i].time) / x(xExtent[1]) * 100;
                stopData.push({
                    offset: offset + "%",
                    color: col
                })
            }

            svg.append("linearGradient")
                .attr("id", "area-gradient-" + chartID)
                .attr("gradientUnits", "userSpaceOnUse")
                .attr("x1", 0).attr("y1", 0)
                .attr("x2", width).attr("y2", 0)
                .selectAll("stop")
                .data(stopData)
                .enter().append("stop")
                .attr("offset", function (d) {
                    return d.offset;
                })
                .attr("stop-color", function (d) {
                    return d.color;
                });

            svg.append("path")
                .datum(data)
                .attr("class", "line")
                .attr("d", line);
            svg.append("path")
                .datum(data)
                .attr("class", "area")
                .attr("d", area)
                .attr("style", "fill: url(#area-gradient-" + chartID + ");");

            svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis)
                .append("text")
                .attr("x", width / 2)
                .attr("y", 20)
                .style("text-anchor", "middle")
                .attr("dy", "0.71em")
                .text("Time of Day");

            const focus = svg.append("g")
                .attr("class", "focus")
                .style("display", "none");

            focus.append("line")
                .attr("class", "line")
                .attr("x1", 0)     // x position of the first end of the line
                .attr("y1", -height / 2)      // y position of the first end of the line
                .attr("x2", 0)     // x position of the second end of the line
                .attr("y2", height / 2);    // y position of the second end of the line

            focus.append("text")
                .attr("x", 9)
                .attr("dy", ".35em");

            svg.append("rect")
                .attr("style", "fill: none; pointer-events: all;")
                .attr("width", width)
                .attr("height", height)
                .on("mouseover", () => focus.style("display", null))
                .on("mouseout", () => focus.style("display", "none"))
                .on("mousemove", mousemove);

            function mousemove(event: MouseEvent) {
                const x0 = x.invert(d3.pointer(event)[0]);
                focus.attr("transform", "translate(" + x(x0) + "," + height / 2 + ")");
                focus.select("text").text(formatTime(x0));
            }
        });
        return () => {
            chartEl.parentElement?.removeChild(chartEl)
        }
    }, [dataFile, drawXAxis]);

    return <div ref={d3Element} style={{width: "100%", height: '100%'}} />
};

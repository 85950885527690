import {useEffect, useRef} from "react";

enum WallDirection {
    CW = "CW",
    CCW = "CCW"
}

interface WallPoint {
    lat: number,
    lng: number,
    angle: number, // in degrees
    height: number, // in metres
}

interface WallPart {
    points: WallPoint[],
    direction: WallDirection,
    hasClimbing: boolean,
}

interface Wall {
    wallParts: WallPart[],
    name: string,
}

interface ClimbingMapProps {
    wall?: Wall;
    windDirection: number
}

function wallPointToLatLngLiteral(wp: WallPoint): google.maps.LatLngLiteral {
    return {
        lat: wp.lat,
        lng: wp.lng,
    }
}


/**
 * The ShowWindControl adds a control to the map.
 * This function takes the control DIV as an argument.
 */
function createWindControl(controlDiv: HTMLElement) {
    // Set CSS for the control border.
    const controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.borderRadius = '2px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginBottom = '22px';
    controlUI.style.marginTop = '10px';
    controlUI.style.textAlign = 'center';
    controlUI.style.width = '80px';
    controlUI.title = 'Click to toggle wind arrows';
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const windControlText = document.createElement('div');
    windControlText.style.color = 'rgb(25,25,25)';
    windControlText.style.fontFamily = 'Roboto,Arial,sans-serif';
    windControlText.style.fontSize = '12px';
    windControlText.style.lineHeight = '27px';
    windControlText.style.paddingLeft = '5px';
    windControlText.style.paddingRight = '5px';
    windControlText.classList.add("noselect")
    windControlText.innerHTML = 'Hide Wind';
    controlUI.appendChild(windControlText);

    return [controlUI, windControlText];
}

export default function ClimbingMap({wall, windDirection}: ClimbingMapProps) {
    const mapElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (mapElement.current == null) return;
        const map: google.maps.Map = new google.maps.Map(mapElement.current, {
            scrollwheel: false,
            // signInControl: false,
            streetViewControl: false,
            center: {lat: -34.397, lng: 150.644},
            zoom: 8,
            mapTypeId: google.maps.MapTypeId.SATELLITE
            //mapTypeId: google.maps.MapTypeId.TERRAIN
        });

        const showWindControlDiv = document.createElement('div');
        const [showWindControl, windControlText] = createWindControl(showWindControlDiv);

        let showWind = true;

        showWindControl.addEventListener('click', function () {
            if (showWind) {
                removeWindDirection(windArrows);
                windControlText.innerHTML = 'Show Wind';
                showWind = false;
            } else {
                windArrows = addWindDirection(markerBounds, windDirection)
                windControlText.innerHTML = 'Hide Wind';
                showWind = true;
            }
        });

        // showWindControlDiv.index = 1;
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(showWindControlDiv);

        const wallStrokeColor = '#009600';
        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYLINE,
            drawingControl: false,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                    google.maps.drawing.OverlayType.POLYLINE
                ]
            },
            polylineOptions: {
                // fillColor: '#ffff00',
                strokeColor: wallStrokeColor,
                // fillOpacity: 1,
                strokeWeight: 5,
                clickable: true,
                editable: false,
                zIndex: 2,
                // icon: {
                //     path: google.maps.SymbolPath.CIRCLE,
                //     scale: 10
                // },
                // linePoints: null, //set later
                // linePointMarkers: null, //set later
                // hasClimbing: true,
                // direction: 1,
                // directionArrows: []
            }
        });

        drawingManager.setMap(map);

        function drawWallDirectionArrows(wallPart: WallPart): google.maps.Polyline[] {
            const wallArrowStrokeColor = '#006000';
            if (wallPart.points.length < 2) return [];
            const result = [];
            for (let i = 0; i < wallPart.points.length - 1; i++) {
                // Probably the simplest way is to set
                // cx = (x1 + x2)/2,
                // cy = (y1+y2)/2,
                // dx = (x2-x1)/2,
                // dy = (y2-y1)/2
                // and then draw a line from (cx-dy, cy+dx) to (cx+dy, cy-dx).
                const p1 = wallPart.points[i];
                const p2 = wallPart.points[i + 1];
                const cx = (p1.lat + p2.lat) / 2;
                const cy = (p1.lng + p2.lng) / 2;
                const dx = (p2.lat - p1.lat) / 2 * (wallPart.direction === WallDirection.CW ? -1 : 1)
                const dy = (p2.lng - p1.lng) / 2 * (wallPart.direction === WallDirection.CW ? -1 : 1)
                const d = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
                const dxN = dx / d;
                const dyN = dy / d;

                result.push(new google.maps.Polyline({
                    path: [
                        {lat: cx, lng: cy},
                        {lat: cx + dyN * 0.00005, lng: cy - dxN * 0.00005}
                    ],
                    icons: [{
                        icon: {
                            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
                        },
                        offset: '100%'
                    }],
                    strokeColor: wallArrowStrokeColor,
                    strokeWeight: 3,
                    map
                }));
            }
            return result;
        }

        function drawWallPart(wallPart: WallPart): google.maps.Polyline {
            const polyLine = new google.maps.Polyline({
                strokeColor: wallStrokeColor,
                map: map,
            });
            polyLine.setPath(wallPart.points.map(wallPointToLatLngLiteral));
            drawWallDirectionArrows(wallPart)
            return polyLine;
        }

        const markerBounds = new google.maps.LatLngBounds();
        wall?.wallParts
            .filter(wallPart => wallPart.hasClimbing)
            .map(drawWallPart);

        wall?.wallParts
            .filter(wallPart => wallPart.hasClimbing)
            .forEach((wallPart) => {
                wallPart.points
                    .forEach(p => markerBounds.extend(wallPointToLatLngLiteral(p)))
            });
        map.fitBounds(markerBounds);

        let windArrows: google.maps.Polyline[] = []

        function removeWindDirection(windArrows: google.maps.Polyline[]) {
            while (windArrows.length) {
                windArrows.pop()?.setMap(null);
            }
        }

        function drawArrow(startPoint: google.maps.LatLng, angleDeg: number, size: number): google.maps.Polyline {
            const lineSymbol = {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
            };

            const cx = startPoint.lat();
            const cy = startPoint.lng();

            const angleRad = angleDeg * Math.PI / 180;

            const x_o = Math.cos(angleRad) * size;
            const y_o = Math.sin(angleRad) * size;

            return new google.maps.Polyline({
                path: [{lat: cx, lng: cy}, {lat: cx + x_o, lng: cy + y_o}],
                icons: [{
                    icon: lineSymbol,
                    offset: '100%'
                }],
                strokeColor: '#33F',
                strokeOpacity: 0.9,
                strokeWeight: 3,
                map: map
            })
        }

        function addWindDirection(bounds: google.maps.LatLngBounds, direction: number) {
            const windArrows: google.maps.Polyline[] = [];

            //arrows should point FROM the wind origin angle
            direction -= 180;

            const sw = markerBounds.getSouthWest();
            const ne = markerBounds.getNorthEast();

            const height = Math.abs(ne.lat() - sw.lat());
            const width = Math.abs(ne.lng() - sw.lng());

            //draw many arrows
            const sep = (height + width) / 2 / 5;
            const extra_h = height / 3;
            const extra_w = width / 3;
            for (let ns = sw.lat() - extra_h; ns <= ne.lat() + extra_h; ns += sep) {
                for (let ew = sw.lng() - extra_w; ew <= ne.lng() + extra_w; ew += sep) {
                    windArrows.push(drawArrow(new google.maps.LatLng(ns, ew), direction, sep * 0.9));
                }
            }
            return windArrows;
        }

        windArrows = addWindDirection(markerBounds, windDirection);
        drawingManager.setDrawingMode(null);

    }, [mapElement, wall?.wallParts, windDirection]);

    return <div ref={mapElement} style={{width: "100%", height: '100%'}}/>
}

export const coolumWall: Wall = {
    name: "Coolum Cave",
    wallParts: [
        {
            direction: WallDirection.CW,
            hasClimbing: true,
            points: [
                {
                    lat: -26.563425979867752,
                    lng: 153.08672899380326,
                    angle: 10,
                    height: 30,
                },
                {
                    lat: -26.563138090542356,
                    lng: 153.08688992634416,
                    angle: 40,
                    height: 20,
                },
                {
                    lat: -26.56301333827674,
                    lng: 153.08712596073747,
                    angle: 40,
                    height: 30,
                },
                {
                    lat: -26.562687062478503,
                    lng: 153.08737272396684,
                    angle: 30,
                    height: 10,
                },
            ]
        },
        {
            direction: WallDirection.CW,
            hasClimbing: false,
            points: [
                {
                    lat: -26.563445172463744,
                    lng: 153.08673972263932,
                    angle: 45,
                    height: 80,
                },
                {
                    lat: -26.563147686864845,
                    lng: 153.08692211285233,
                    angle: 45,
                    height: 80,
                },
                {
                    lat: -26.5630517236037,
                    lng: 153.08713668957353,
                    angle: 45,
                    height: 80,
                },
                {
                    lat: -26.56267746611744,
                    lng: 153.0874585546553,
                    angle: 45,
                    height: 80,
                },
            ]
        },
        {
            direction: WallDirection.CCW,
            hasClimbing: false,
            points: [
                {
                    lat: -26.563387594666157,
                    lng: 153.08662170544267,
                    angle: 0,
                    height: 100,
                },
                {
                    lat: -26.564097718814537,
                    lng: 153.08593505993485,
                    angle: 0,
                    height: 100,
                },
                {
                    lat: -26.56448156787338,
                    lng: 153.08493727818131,
                    angle: 0,
                    height: 100,
                },
            ]
        },
        {
            direction: WallDirection.CW,
            hasClimbing: false,
            points: [
                {
                    lat: -26.562514327856302,
                    lng: 153.0873834528029,
                    angle: 0,
                    height: 100,
                },
                {
                    lat: -26.56177540458906,
                    lng: 153.08730835095048,
                    angle: 0,
                    height: 100,
                },
                {
                    lat: -26.561468318193494,
                    lng: 153.0871474184096,
                    angle: 0,
                    height: 100,
                },
            ]
        },
    ]
}

import {AppBar, Button, Toolbar, Typography} from "@mui/material";
import { Outlet } from 'react-router-dom';

export default function Root() {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" compent="div" sx={{ flexGrow: 1 }}>
            Climb Clime
          </Typography>
          <Button clolor="inherit">Login</Button>
        </Toolbar>
      </AppBar>
      <Outlet/>
    </>
  )
}